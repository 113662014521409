const app = new Vue({
    el: '#app',

    data: {
        clientHeight: 0,
        windowHeight: 0,
        windowWidth: 0,
        isScrolling: false,
        scrollPosition: 0,
        footerStuck: false,
        mounted: false,
        mobileMenuOpen: false,
        photoGalleryOpen: false,
        ConnectOpen: false,
        activeTrip: ''
    },

    methods: {
        handleScroll () {
            this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY

            if(!this.mobileMenuOpen){
                this.isScrolling = (this.scrollPosition > 0)
            }
        },

        toggleMenu() { 
            this.mobileMenuOpen = ! this.mobileMenuOpen;
        }, 
        
        toggleConnect() {
            this.ConnectOpen = ! this.ConnectOpen;
        },

        toggleActiveTrip(trip, scroll) {
            console.log(scroll)
            if(this.activeTrip == trip) { 
                this.activeTrip = '' 
            } else { 
                this.activeTrip = trip 

                if(scroll !== null) {
                    VueScrollTo.scrollTo(scroll, {
                        duration: 1000,
                        easing: "ease",
                        offset: -100,
                    });
                }
            }
        },

        checkUrl(){
            if(window.location.hash.substring(0, 1) === "#"){
                VueScrollTo.scrollTo(window.location.hash);
            }
        }

    },

    mounted () {
        this.handleScroll();
        this.mounted = true;
        this.checkUrl();
    },

    created () {
        window.addEventListener('scroll', this.handleScroll);
    },

    destroyed () {
        window.removeEventListener('scroll');
    },

})
