<template>
  <inline-svg 
    :src="'/assets/icons/' + name + '.svg'" 
    :width="width" 
    :height="height"
    fill="currentColor"
    :aria-label="alt"
    :title="alt"
  ></inline-svg>
</template>
<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Icon',
  components: {
    InlineSvg
  },
  props: {
    name: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: 'icon'
    },
    height: {
      type: String,
      default: '18'
    },
    width: {
      type: String,
      default: '18'
    }
  }
}
</script>
