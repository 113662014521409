window.Vue = require('vue')
// Vue.config.devtools = true

// require Vue components that exist on all pages
require('./common.js')

// Vue Components that reside only on front page
Vue.component('video-background', require('./components/VideoBackground.vue').default);

// Define app
require('./app.js')