import VueParallaxJs from 'vue-parallax-js'
Vue.use(VueParallaxJs)

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    preLoad: 1.1,
    error: '',
    loading: '',
    attempt: 1
})

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
    container: "body",
    duration: 1000,
    easing: "ease",
    offset: -80,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

Vue.component('icon', require('./components/Icon.vue').default);
Vue.component('social-icons', require('./components/SocialMediaIcons.vue').default);
Vue.component('mobile-menu', require('./components/MobileNavMenu.vue').default);