<template>
    <div class="menu-container" :class="{ 'loaded': loaded, 'd-none': !loaded }" >
        <div class="mobile-nav-top">
            <div class="close-button d-flex align-items-center justify-content-end text-red p-3" @click="closeMenu()" >
                close menu <icon name="solid/times" height="20" width="20" class="ml-2" ></icon>
            </div>
        </div>
        <div class="px-4 pb-4">
            <div v-for="(navitem, index) in mobileNavData" v-bind:key="index">
                <div class="pt-2 pb-1 d-flex justify-content-between" >
                    <a :href="navitem.url" class="mobile-nav-link" :target="navitem.target != '' ? navitem.target : '_self'" >
                        {{ decodeHtml(navitem.title) }}
                    </a>
                </div>
                <div class="dropdown-container px-2" >
                    <div v-for="(child, i) in navitem.children" v-bind:key="i" >
                        <a :href="child.url" :class="'p-2 d-block'" :target="child.target != '' ? child.target : '_self'" >{{ decodeHtml(child.title) }}</a>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    props: {
        'menu': {
            type: String,
            default: ''
        }
    },

    data(){
        return {
            loaded: false,
            mobileNavData: [],
            endpoint: ''
        }
    },

    watch: {
        endpoint: function () {
            this.mobileNavData = [];
            this.getMenu();
        }
    },

    created() {
        this.endpoint = this.menu; 
        this.getMenu();
    },

    methods: {

        decodeHtml(html) {
            var txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },

        getMenu() {
            let vm = this;

            axios.get("/wp-json/kerigansolutions/v1/navigation-menu?slug=" + this.endpoint)
            .then(response => {
 
                vm.mobileNavData = Object.keys(response.data).map((item) => {
                    response.data[item].subMenuOpen = false;
                    if(response.data[item].children.length > 0){
                        response.data[item].subMenuOpen = true;
                    }
                    return response.data[item]
                })
                vm.loaded = true;
            })

            
        },

        closeMenu() {
            // this.loaded = false;
            this.$emit('closemenu', this.menu);
        }
    }
}
</script>
